export default class Mousemove {
  constructor({ el, cb }) {
    this.cb = cb
    this.el = el ? T.is.str(el) ? T.select(el) : el : document
    T.bM(this, ['run'])
  }

  on() {
    this.listener('a')
  }

  off() {
    this.listener('r')
  }

  listener(t) {
    T.detect.isMobile ? T.listen(this.el, t, 'touchmove', this.run) : T.listen(this.el, t, 'mousemove', this.run)
  }
  
  run(e) {
    const x = e.changedTouches ? e.changedTouches[0].pageX : e.pageX
    const y = e.changedTouches ? e.changedTouches[0].pageY : e.pageY

    this.cb(x, y, e)
  }
}
