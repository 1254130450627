const Ease = {
  linear(t) {
    return t
  },
  i1(t) {
    return 1 - Math.cos(t * (Math.PI / 2))
  },
  o1(t) {
    return Math.sin(t * (Math.PI / 2))
  },
  io1(t) {
    return -.5 * (Math.cos(Math.PI * t) - 1)
  },
  i2(t) {
    return t * t
  },
  o2(t) {
    return t * (2 - t)
  },
  io2(t) {
    return t < .5 ? 2 * t * t : (4 - 2 * t) * t - 1
  },
  i3(t) {
    return t * t * t
  },
  o3(t) {
    return --t * t * t + 1
  },
  io3(t) {
    return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  },
  i4(t) {
    return t * t * t * t
  },
  o4(t) {
    return 1 - --t * t * t * t
  },
  io4(t) {
    return t < .5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t
  },
  i5(t) {
    return t * t * t * t * t
  },
  o5(t) {
    return 1 + --t * t * t * t * t
  },
  io5(t) {
    return t < .5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t
  },
  i6(t) {
    return 0 === t ? 0 : Math.pow(2, 10 * (t - 1))
  },
  o6(t) {
    return 1 === t ? 1 : 1 - Math.pow(2, -10 * t)
  },
  io6(t) {
    return 0 === t ? 0 : 1 === t ? 1 : (t /= .5) < 1 ? .5 * Math.pow(2, 10 * (t - 1)) : .5 * (2 - Math.pow(2, -10 * --t))
  }
}

export default Ease