export default class Project {
  constructor() {
    this.a = window.App

    this.mobile = T.detect.isMobile

    this.fade = T.M({
      el: T.select('.fade'),
      p: {
        o: [0, 1]
      },
      d: 700,
      e: 'o6'
    })
  }

  in() {
    this.a.transition.removeOld()
    this.a.transition.insertNew()
    window.scrollTo(0, 0)
    this.a.core.init()
    this.a.core.on()

    const title = T.selectAll('.c-hero__title .c')

    if (this.mobile) {
      this.fade.play({
        reverse: true,
        cb: null
      })
    }

    for (let i = 0; i < title.length; i++) {
      T.M({
        el: title[i],
        delay: 30 * i,
        e: 'o6',
        d: 1200,
        p: {
          y: [100, 0]
        }
      }).play()
    }

    this.a.mutating = false
  }

  out({ done, data }) {
    this.a.mutating = true

    if (this.mobile && !data.fromHome) {
      this.fade.play({
        cb: () => done()
      })
    } else {
      done()
    }
  }
}