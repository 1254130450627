import TransitionManager from './utils/TransitionManager'
import Transition from './utils/Transition'
import Grid from './utils/Grid'
import Project from './utils/Project'
import './utils/Tools'

export default class App {
  constructor() {
    'serviceWorker' in navigator && navigator.serviceWorker.register('/sw.js')

    this.a = window.App

    this.a.transitions =  new TransitionManager({
      transitions: {
        default: Transition,
        custom: {
          project: Project
        }
      }
    })

    this.a.config.env === 'dev' && new Grid({
      width: 99,
      col: 24,
      gap: 10,
      color: '#ba295e',
      full: false
    })
  }
}
