import Raf from './Raf'

let o = 0
const raf = new Raf()

export default class RafR {
  constructor(t) {
    this.cb = t
    this.on = false
    this.id = o

    o++
  }

  run() {
    if (!this.on) {
      raf.add({
        id: this.id,
        cb: this.cb
      })

      this.on = true
    }
  }

  stop() {
    if (this.on) {
      raf.remove(this.id)
      this.on = false
    }
  }
}