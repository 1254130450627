import VirtualScrollM from './VirtualScrollM'

export default class ScrollM {
  constructor() {
    T.bM(this, ['scrollY'])

    this.a = window.App
    this.a.scroll = {
      y: 0
    }

    this.scroll = new VirtualScrollM({
      cb: {
        y: this.scrollY
      }
    })

  }

  init() {
    this.a.scroll.y = 0
  }

  on() {
    this.scroll.on()
  }

  off() {
    this.scroll.off()
  }

  resize() {}

  update() {}

  scrollY(e) {
    this.a.scroll.y = e
  }
}