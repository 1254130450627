export default class Router {
  constructor() {
    this.a = window.App
    this.routes = this.a.config.routes
  }

  mutation(r) {
    const p = this.routes[r] || '404'
    const c = this.a.route.new

    this.a.route.old = c
    this.a.route.new = {
      url: r,
      page: p
    }

    // this.a.is[c.page] = false
    // this.a.is[route] = true
  }
}