import Block from './Block'

export default class Next extends Block {
  init() {
    T.bM(this, ['onClick'])

    this.a = window.App

    this.elems()
    this.listener()
    this.observer()

    this.second = 0
    this.counterInterval = null
    this.anims = {
      counter: []
    }

    this.setCounter()

    this.anims.intro = T.M({
      el: this.image,
      p: {
        y: [80, 0]
      },
      e: 'io6',
      d: 1200,
      cb: () => {
        this.a.transitions.mutate(this.image.dataset.url, 'project')
      }
    })

    this.anims.o = T.M({
      el: [this.cta, this.indexes],
      p: {
        o: [1, 0]
      },
      e: 'o6',
      d: 800
    })

    this.anims.c = T.M({
      el: this.counter,
      p: {
        o: [1, 0]
      },
      e: 'o6',
      d: 800
    })
  }

  on() {}

  elems() {
    this.image = T.select('.c-next__img', this.el)
    this.cta = T.select('.c-next__cta', this.el)
    this.indexes = T.select('.c-next__index', this.el)
    this.counter = T.select('.c-next__counter', this.el)
    this.counters = T.selectAll('.c-next__no', this.el)
  }

  listener() {
    T.listen(this.image, 'a', 'click', this.onClick)
  }

  setCounter() {
    this.anims.counter = []

    for (let i = 0; i < this.counters.length; i++) {
      const c = T.M({
        el: this.counters[i].children[0],
        p: {
          y: [100, 0]
        },
        e: 'o6',
        d: 1000
      })

      i === 0 && c.play()
      this.anims.counter.push(c)
    }
  }

  observer() {
    const obs = new IntersectionObserver((els) => {
      els.forEach((entry) => {
        this.counter.classList.toggle('v', entry.isIntersecting)
        
        if (entry.isIntersecting) {
          this.counterInterval = setInterval(() => {
            this.anims.counter[this.second].play({
              p: {
                y: {
                  newEnd: -100
                }
              }
            })

            this.second++
            this.anims.counter[this.second].play()

            if (this.second > 2) {
              clearInterval(this.counterInterval)
              this.onClick()
            }
          }, 1000)
        }
        else {
          clearInterval(this.counterInterval)
          this.second = 0

          for (let i = 0; i < this.anims.counter.length; i++) {
            if (i === 0) this.anims.counter[i].play()
            else this.anims.counter[i].play({
              reverse: true,
              d: 100
            })
          }
        }
      })
    })

    obs.observe(this.counter)
  }

  onClick() {
    this.a.mutating = true
    this.anims.c.play()
    clearInterval(this.counterInterval)

    if (T.detect.isMobile) {
      this.a.transitions.mutate(this.image.dataset.url, 'project', 500)
    } else {
      if (this.a.scroll.y === this.a.scroll.limit.y) {
        this.anims.o.play()
        this.anims.intro.play()
      } else {
        this.a.scroll.scrollTo.go(this.a.scroll.limit.y, {
          cb: () => {
            this.anims.o.play()
            this.anims.intro.play()
          }
        })
      }
    }
  }
}