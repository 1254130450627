import Block from './Block'

export default class Contact extends Block {
  init() {
    this.anims = []

    this.elems()
    this.prepare()
  }

  elems() {
    this.titles = T.selectAll('.b-contact__title', this.el)
    this.contents = T.selectAll('.b-contact__content', this.el)
    this.lines = T.selectAll('.line', this.el)
  }

  on() {
    for (let i = 0; i < this.anims.length; i++) this.anims[i].play()
  }

  prepare() {
    for (let i = 0; i < this.titles.length; i++) {
      const t = T.M({
        el: this.titles[i].children[0],
        p: {
          y: [100, 0]
        },
        e: 'o6',
        d: 1200,
        delay: 250 + 150 * i
      })

      const c = T.M({
        el: this.contents[i].children[0],
        p: {
          y: [100, 0]
        },
        e: 'o6',
        d: 1200,
        delay: 250 + 150 * i
      })

      const l = T.M({
        el: this.lines[i],
        p: {
          scaleX: [0, 1]
        },
        e: 'o6',
        d: 1200,
        delay: 250 + 150 * i
      })
      
      this.anims.push(t, c, l)
    }
  }
}
