import Core from './Core'
import Sprite from './Sprite'

export default class Loader {
  constructor(p) {
    this.a = window.App

    this.elems()

    document.fonts.ready.then(() => {
      this.wrapper.classList.add('r')
      this.a.core = new Core()
      this.load()
      new Sprite({ el: this.sprite })
    })
  }

  elems() {
    this.loader = T.select('.loader')
    this.inner = T.select('.loader__i')
    this.wrapper = T.select('.loader__w')
    this.sprite = T.select('.loader__sprite')
    this.no = T.select('.no')
  }

  load() {
    T.M({
      d: 2200,
      e: 'io4',
      update: (e) => {
				const p = Math.round(e.progressE * 100) / 100 * 100

        this.no.innerText = Math.floor(p)
      },
      cb: () => {
        this.hide()
      }
    }).play()
  }

  hide() {
    T.M({
      el: this.loader,
      p: {
        y: [0, -100]
      },
      e: 'io6',
      d: 1500
    }).play()

    T.M({
      el: this.inner,
      p: {
        y: [0, 90]
      },
      e: 'io6',
      d: 1500
    }).play()

    T.M({
      el: this.sprite,
      p: {
        scale: [1, 0.9]
      },
      e: 'io6',
      d: 1500
    }).play()

    setTimeout(() => this.cb(), 700)
  }

  cb() {
    this.a.core.on()
  }
}
