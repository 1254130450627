import Motion from './Motion'
import Split from './Split'

window.T = {
  M: (e) => new Motion(e),
  Split: (e) => new Split(e),
  lerp: (s, e, v) => {
    return s * (1 - v) + e * v
  },
  bM: (t, f) => {
    for  (let i = 0; i < f.length; i++)
      t[f[i]] = t[f[i]].bind(t)
  },
  listen: (e, a, ev, fn) => {
    const d = document
    const el = typeof e === 'string' ? (e.charAt(0) === '.' || e.charAt(0) === '[' ? T.selectAll(e) : [T.select(e)]) : [e]
    const events = ['mouseWheel', 'mousemove', 'touchmove', 'touchstart'];
    const opts = events.indexOf(ev) !== -1 && { passive: true }
    const action = a === 'a' ? 'add' : 'remove'
    let event = ev

    if (ev === events[0]) event = 'on' + 'wheel' in d ? 'wheel' : d.onmousewheel !== void 0 ? 'mouse' + 'wheel' : 'DOMMouseScroll'

    for (let i = 0; i < el.length; i++) el[i][action + 'EventListener'](event, fn, opts)
  },
  pD: (e) => {
    e.cancelable && e.preventDefault()
  },
  detect: {
    uA: navigator.userAgent.toLowerCase(),
    get iPadIOS13() {
     return 'MacIntel' === navigator.platform && 1 < navigator.maxTouchPoints
    },
    get isMobile() {
      return /mobi|android|tablet|ipad|iphone/.test(this.uA) || this.iPadIOS13
    },
    get isFirefox() {
      return -1 < this.uA.indexOf('firefox')
    }
  },
  select: (s, e = document) => {
    return e.querySelector(s)
  },
  selectAll: (s, e = document) => {
    return e.querySelectorAll(s)
  },
  is: {
    num: (e) => e % 1 === 0,
    dom: (e) => e instanceof Element || e instanceof HTMLDocument,
    def: (e) => e !== undefined,
    str: (e) => typeof e === 'string'
  },
  clamp: (v, min, max) => {
    return Math.min(Math.max(v, min), max)
  },
  round: (v, d) => {
    const e = d ? Math.pow(10, d) : 100
  
    return Math.round(v * e) / e
  },
  wrap: (v, min, max) => {
    const r = max - min
    const func = v => ((r + (v - min) % r) % r) + min
  
    return v || v === 0 ? func(v) : func
  }
}
