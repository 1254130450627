export default class Transition {
  constructor() {
    this.a = window.App
    this.m = T.M({
      el: document.querySelector('.slide'),
      p: {
        y: [100, 0]
      },
      d: 800
    })
  }

  in() {
    this.a.nav.opened && this.a.nav.toggle()
    this.a.transition.removeOld()
    this.a.transition.insertNew()
    window.scrollTo(0, 0)
    this.a.core.init()
    this.a.core.on()
    this.a.mutating = false

    this.m.play({
      p: {
        y: {
          newEnd: -100
        }
      },
      e: 'o4',
      cb: null
    })
  }

  out({ done }) {
    const page = T.select('.page')

    T.M({
      el: page,
      p: {
        y: [0, -120, 'px']
      },
      e: 'io3',
      d: 800
    }).play()
    
    this.a.nav.opened && this.a.nav.removeOverlay()
    this.m.play({
      p: {
        y: {
          newStart: 100
        }
      },
      e: 'io4',
      cb: () => done()
    })
  }
}
