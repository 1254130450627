import Block from "./Block"

export default class AboutHero extends Block {
  init() {
    this.elems()

    this.splitContent = T.Split({
      el: this.content,
      wrap: true
    })

    this.splitContent.split()

    this.anims = []
    
    for (let i = 0; i < this.texts.length; i++) {
      const l = T.M({
        el: this.lines[i],
        p: {
          scaleX: [0, 1]
        },
        e: 'o6',
        d: 1200,
        delay: 250 + 100 * i
      })

      const t = T.M({
        el: this.texts[i],
        p: {
          y: [105, 0],
          rotate: [i === 1 ? -4 : 4, 0]
        },
        e: 'o6',
        d: 1200,
        delay: 250 + 100 * i
      })

      this.anims.push(l, t)
    }

    const img = T.M({
      el: this.img,
      p: {
        y: [100, 0]
      },
      e: 'o6',
      d: 1200,
      delay: 400
    })

    const inner = T.M({
      el: this.img.children[0],
      p: {
        y: [-100, 0],
        scale: [1.1, 1]
      },
      e: 'o6',
      d: 1200,
      delay: 400
    })
    
    const content = T.M({
      el: this.splitContent.lines,
      p: {
        y: [101, 0]
      },
      e: 'o6',
      d: 1200,
      delay: 600,
      stagger: 75
    })

    this.anims.push(img, inner, content)
  }

  on() {
    this.animate()
  }

  elems() {
    this.texts = T.selectAll('.text-line', this.el)
    this.lines = T.selectAll('.line', this.el)
    this.content = T.select('.b-about-hero__content', this.el)
    this.img = T.select('.b-about-hero__img--i', this.el)
  }

  animate() {
    for (let i = 0; i < this.anims.length; i++) this.anims[i].play()
  }
}
