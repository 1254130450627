import ScrollTo from "./ScrollTo"
import VirtualScroll from "./VirtualScroll"

export default class Scroll {
  constructor({ lerp = 0.09 } = {}) {
    this.a = window.App
    this.a.scroll = {
      y: 0,
      needS: false,
      limit: {
        y: 0
      }
    }

    this.lerp = lerp

    this.store = {
      x: 0,
      y: 0,
      min: 0,
      max: 0,
      isDown: false,
      prev: 0
    }

    this.scroll = {
      y: {
        current: 0,
        target: 0
      }
    }

    T.bM(this, ['scrollY', 'yUp'])

    this.vS = new VirtualScroll({
      cb: this.scrollY,
      key: true
    })

    this.sTo = new ScrollTo({
      yUp: this.yUp
    })

    this.a.scroll.scrollTo = this.sTo
  }

  init() {
    this.sUp({
      y: 0
    })
  }

  on() {
    this.vS.on()
    this.sTo.on()
    this.resize()
  }

  off() {
    this.vS.off()
    this.sTo.off()
  }

  resize() {
    const max = T.selectAll('.page')[0].offsetHeight - this.a.w.wh

    this.store.max = max
    this.a.scroll.limit.y = max
    this.vS.resize()

    const y = this.clamp(this.scroll.y.target)

    this.store.max === y && this.sUp({ y })
  }

  scrollY(e) {
    if (this.a.mutating) return
    
    this.sTo.stop()
    !this.store.isDown && this.yUp(this.clamp(this.scroll.y.target + e))
  }

  yUp(t) {
    this.scroll.y.target = t
  }

  sUp(p) {
    this.scroll.y.target = p.y
    this.scroll.y.current = p.y
    this.a.scroll.y = this.scroll.y.target
  }

  clamp(e) {
    return T.round(T.clamp(e, 0, this.store.max))
  }

  update() {
    this.a.scroll.needS = this.a.scroll.y !== this.scroll.y.target

    if (this.a.scroll.needS) {
      const translate = this.scroll.y.target - this.scroll.y.current

      this.scroll.y.current += translate * this.lerp
      this.a.scroll.y = T.round(this.scroll.y.current)
    }
  }
}