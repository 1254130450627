import Intersect from '../helpers/Intersect';
import Scroll from '../helpers/Scroll';
import ScrollM from '../helpers/ScrollM'
import Renderer from '../routes/Renderer'
import Menu from './Menu';
import RafR from './RafR'

export default class Core {
  constructor() {
    this.a = window.App

    T.bM(this, ['resize', 'update', 'backHome'])

    this.a.nav = new Menu()
    if (T.detect.isMobile) {
      this.scroll = new ScrollM()
    } else {
      this.scroll = new Scroll()
    }
    this.intersect = new Intersect()
    
    this.ro = T.detect.isMobile ? 'orientationchange' : 'resize'
    T.listen(window, 'a', this.ro, this.resize)
    
    T.listen(document, 'a', 'keydown', this.backHome)

    this.raf = new RafR(this.update)

    // TODO : Call launch/init after loader
    this.launch()
    this.init()
  }

  launch() {
    this.raf.run()
  }

  init() {
    this.scroll.init()
    this.intersect.init()
    
    this.resize()
    
    this.a.renderer.init()
  }

  on() {
    this.a.renderer.on()
    this.scroll.on()
  }

  off() {
    this.a.renderer.off()
    this.scroll.off()
  }

  resize() {
    this.a.w = {
      ww: window.innerWidth,
      wh: window.innerHeight
    }

    this.a.renderer.resize()
    this.scroll.resize()
    this.intersect.resize()
  }

  update() {
    this.a.renderer.update()
    this.scroll.update()
    this.a.scroll.needS && this.intersect.run()
  }

  backHome(e) {
    e.key === 'Escape' && this.a.route.new.url !== '/' && this.a.transitions.mutate('/')
  }
}