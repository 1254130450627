import Block from './Block'

export default class Hero extends Block {
  init() {
    this.anims = []

    this.elems()

    const i = T.M({
      el: [this.index, this.back],
      e: 'o6',
      d: 1200,
      p: {
        o: [0, 1]
      }
    })

    this.anims.push(i)

    if (this.infos) {
      for (let i = 0; i < this.infos.length; i++) {
        const a = T.M({
          el: this.infos[i],
          delay: 100 * i,
          e: 'o6',
          d: 1000,
          p: {
            y: [100, 0]
          }
        })

        this.anims.push(a)
      }
    }
  }

  on() {
    this.animate()
  }

  elems() {
    this.infos = T.selectAll('.c-hero__info', this.el)
    this.arrow = T.select('.c-hero__arrow', this.el)
    this.index = T.select('.c-hero__index', this.el)
    this.back = T.select('.c-hero__back', this.el)
  }

  animate() {
    for (let i = 0; i < this.anims.length; i++) this.anims[i].play()

    this.el.classList.add('v')
    this.arrow.classList.add('v')
  }
}