/* eslint-disable max-lines */
import DelayRaf from './DelayRaf'
import RafR from './RafR'
import Ease from './Ease'

export default class Motion {
  constructor(props) {
    this.bindMethods()

    this.running = false
    this.rafs = []
    this.staggers = []
    this.v = this.init(props)
    this.v.isDom ? this.updateProp() : this.updateValue()

    if (this.v.stagger.d) {
      for (let i = 0; i < this.v.length; i++) {
        this.rafs[i] = new RafR(this.runStagger.bind(this, i))
      }
    } else {
      this.raf = new RafR(this.run)
    }
  }

  bindMethods() {
    this.updateProp = this.updateProp.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.gRaf = this.gRaf.bind(this)
    this.sRaf = this.sRaf.bind(this)
    this.run = this.run.bind(this)
  }

  init(props) {
    const el = props.el instanceof HTMLCollection || props.el instanceof NodeList || Array.isArray(props.el) ? props.el : [props.el]
    const instance = {
      el,
      e: {
        curve: props.e || 'linear'
      },
      stagger: {
        d: props.stagger,
        els: []
      },
      delay: props.delay || 0,
      cb: props.cb || false,
      r: props.r || 3,
      d: {
        origin: props.d || 1000,
        current: 0
      },
      isDom: false,
      progress: 0,
      progressE: 0,
      elapsed: 0
    }

    for (let i = 0; i < el.length; i++) {
      if (T.is.dom(el[i])) {
        instance.isDom = true
        break
      }
    }

    if (instance.stagger.d) {
      instance.up = []

      if (props.staggerUpdate) {
        for (let i = 0; i < props.staggerUpdate.length; i++) {
          instance.stagger.els[i] = {
            current: 0,
            elapsed: 0,
            progress: 0,
            progressE: 0
          }

          instance.up[i] = function() {
            props.staggerUpdate[i](instance.stagger.els[i])
          }
        }
      } else {
        for (let i = 0; i < el.length; i++) {
          instance.stagger.els[i] = {
            current: 0,
            elapsed: 0,
            progress: 0,
            progressE: 0
          }

          if (props.update) {
            instance.up[i] = function() {
              props.update(instance.stagger.els[i], i)
            }
          } else instance.up[i] = this.updateProp
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (Object.prototype.hasOwnProperty.call(props, 'update')) {
        instance.up = function() {
          props.update(instance)
        }
      } else if (instance.isDom) instance.up = this.updateProp
      else instance.up = this.updateValue
    }

    const properties = props.p

    if (properties) {
      instance.prop = {}
      instance.propI = []
      
      const n = Object.keys(props.p)

      instance.length = T.is.num(props.el.length) ? props.el.length : 1
      instance.propLength = n.length
  
      for (let i = 0; i < instance.propLength; i++) {
        const property = n[i]
  
        instance.prop[i] = {
          name: property,
          origin: {
            start: properties[property][0],
            end: properties[property][1]
          },
          current: properties[property][0],
          start: properties[property][0],
          end: properties[property][1],
          unit: properties[property][2] || '%'
        }

        instance.propI[property] = i
      }
    } else if (props.staggerUpdate) {
      instance.length = props.staggerUpdate.length
    } else if (props.stagger && props.update) {
      instance.length = props.el.length
    }

    return instance
  }

  updateValue() {
    const prop = this.v.prop

    for (let i = 0; i < this.v.propLength; i++) {
      prop[i].current = this.lerpValue(prop[i].start, prop[i].end)

      for (let j = 0; j < this.v.length; j++) {
        this.v.el[j][prop[i].name] = prop[i].current
      }
    }
  }
 
  updateProp(index) {
    const prop = this.v.prop
    const item = this.v.propI

    for (let i = 0; i < this.v.propLength; i++) {
      prop[i].current = this.lerpValue(prop[i].start, prop[i].end, index)

      const tX = Object.prototype.hasOwnProperty.call(item, 'x') ? prop[item.x].current + prop[item.x].unit : 0
      const tY = Object.prototype.hasOwnProperty.call(item, 'y') ? prop[item.y].current + prop[item.y].unit : 0
      const sX = Object.prototype.hasOwnProperty.call(item, 'scaleX') ? prop[item.scaleX].current : null
      const sY = Object.prototype.hasOwnProperty.call(item, 'scaleY') ? prop[item.scaleY].current : null
      const s = Object.prototype.hasOwnProperty.call(item, 'scale') ? prop[item.scale].current : null
      const rX = Object.prototype.hasOwnProperty.call(item, 'rotateX') ? prop[item.rotateX].current : null
      const rY = Object.prototype.hasOwnProperty.call(item, 'rotateY') ? prop[item.rotateY].current : null
      const r = Object.prototype.hasOwnProperty.call(item, 'rotate') ? prop[item.rotate].current : null
      const o = Object.prototype.hasOwnProperty.call(item, 'o') ? prop[item.o].current : -1

      const t = tX + tY === 0 ? 0 : `translate3d(${tX}, ${tY}, 0)`
      const scaleX = sX === null ? 0 : `scaleX(${sX})`
      const scaleY = sY === null ? 0 : `scaleY(${sY})`
      const scale = s === null ? 0 : `scale(${s})`
      const rotateX = rX === null ? 0 : `rotateX(${rX}deg)`
      const rotateY = rY === null ? 0 : `rotateY(${rY}deg)`
      const rotate = r === null ? 0 : `rotate(${r}deg)`

      const transform = t + scaleX + scaleY + scale + rotateY + rotateX + rotate === 0 ? 0 : [t, scaleX, scaleY, scale, rotateX, rotateY, rotate].filter((p) => p !== 0)
        .join(' ')

      if (T.is.def(index)) {
        transform !== 0 && (this.v.el[index].style.transform = transform)
        o >= 0 && (this.v.el[index].style.opacity = o)
      } else {
        for (let j = 0; j < this.v.length; j++) {
          transform !== 0 && (this.v.el[j].style.transform = transform)
          o >= 0 && (this.v.el[j].style.opacity = o)
        }
      }

    }
  }

  play(t) {
    this.vUpdate(t)

    if (this.v.stagger.d) {
      this.pause()
      this.running = true
      this.delay.run()
    } else {
      this.pause()
      this.running = true
      this.delay.run()
    }
  }

  pause() {
    // eslint-disable-next-line no-negated-condition
    if (this.v.stagger.d) {
      for (let i = 0; i < this.rafs.length; i++) {
        this.rafs[i].stop()
        this.staggers[i] && this.staggers[i].stop()
      }
    } else {
      this.raf.stop()
      this.delay && this.delay.stop()
    }

    this.running = false
  }

  pauseStagger(i) {
    this.rafs[i].stop()
    this.staggers[i].stop()

    this.running = false
  }

  vUpdate(t) {
    const v = t || {}
    const e = Object.prototype.hasOwnProperty.call(v, 'reverse') && v.reverse ? 'start' : 'end'

    for (let i = 0; i < this.v.propLength; i++) {
      this.v.prop[i].end = v.restart ? this.v.prop[i].origin.end : this.v.prop[i].origin[e]
      this.v.prop[i].start = v.restart ? this.v.prop[i].origin.start : this.v.prop[i].current

      if (Object.prototype.hasOwnProperty.call(v, 'p')) {
        Object.prototype.hasOwnProperty.call(v.p, this.v.prop[i].name) && (Object.prototype.hasOwnProperty.call(v.p[this.v.prop[i].name], 'newEnd') && (this.v.prop[i].end = v.p[this.v.prop[i].name].newEnd))
        Object.prototype.hasOwnProperty.call(v.p[this.v.prop[i].name], 'newStart') && (this.v.prop[i].start = v.p[this.v.prop[i].name].newStart)
      }
    }

    if (this.v.stagger.d) {
      for (let i = 0; i < this.v.length; i++) {
        const stagger = this.v.stagger.d < 0 ? Math.abs(this.v.stagger.d) * (this.v.length - (i + 1)) : this.v.stagger.d * i

        this.staggers[i] = new DelayRaf(this.staggerRaf.bind(this, i), stagger)
        this.v.stagger.els[i].current = Object.prototype.hasOwnProperty.call(v, 'd') ? v.d : T.round(this.v.d.origin - this.v.stagger.els[i].current + this.v.stagger.els[i].elapsed)
        this.v.stagger.els[i].progress = this.v.stagger.els[i].current === 0 ? 1 : 0
        this.v.stagger.els[i].progressE = this.v.stagger.els[i].current === 0 ? 1 : 0
      }
    }

    this.v.d.current = Object.prototype.hasOwnProperty.call(v, 'd') ? v.d : T.round(this.v.d.origin - this.v.d.current + this.v.elapsed)
    this.v.e.curve = v.e || this.v.e.curve
    this.v.e.calc = Ease[this.v.e.curve]
    this.v.delay = Object.prototype.hasOwnProperty.call(v, 'delay') ? v.delay : this.v.delay
    this.v.cb = Object.prototype.hasOwnProperty.call(v, 'cb') ? v.cb : this.v.cb
    this.v.progress = this.v.d.current === 0 ? 1 : 0
    this.v.progressE = this.v.d.current === 0 ? 1 : 0
    this.delay = this.v.stagger.d ? new DelayRaf(this.sRaf, this.v.delay) : new DelayRaf(this.gRaf, this.v.delay)
  }

  staggerRaf(i) {
    this.rafs[i].run()
  }

  sRaf() {
    for (let i = 0; i < this.rafs.length; i++) {
      this.staggers[i].run()
    }
  }

  gRaf() {
    this.raf.run()
  }

  runStagger(i, e) {
    if (this.v.stagger.els[i].progress === 1) {
      this.pauseStagger(i)
      this.v.up[i](i)
      this.v.length - 1 === i && this.v.cb && this.v.cb()
    } else {
      this.v.stagger.els[i].elapsed = Math.min(Math.max(e, 0), this.v.stagger.els[i].current)
      this.v.stagger.els[i].progress = Math.min(Math.max(this.v.stagger.els[i].elapsed / this.v.stagger.els[i].current, 0), 1)
      this.v.stagger.els[i].progressE = this.v.e.calc(this.v.stagger.els[i].progress)
      this.v.up[i](i)
    }
  }

  run(e) {
    if (this.v.progress === 1) {
      this.pause()
      this.v.up()
      this.v.cb && this.v.cb()
    } else {
      this.v.elapsed = Math.min(Math.max(e, 0), this.v.d.current)
      this.v.progress = Math.min(Math.max(this.v.elapsed / this.v.d.current, 0), 1)
      this.v.progressE = this.v.e.calc(this.v.progress)
      this.v.up()
    }
  }

  lerpValue(s, e, i) {
    const progress = T.is.def(i) ? this.v.stagger.els[i].progressE : this.v.progressE

    return T.round(T.lerp(s, e, progress), this.v.r)
  }
}
