import Mousemove from './Mousemove'

export default class FollowMouse {
  constructor({ parent, el, resetToPosition = false, cb, rotate, rotateValue = 5, rotateForce = 0.4 }) {
    this.parent = parent || window
    this.el = el
    this.resetToPosition = resetToPosition
    this.cb = cb
    this.rotate = true
    this.rotateValue = rotateValue
    this.rotateForce = rotateForce
    this.enter = false
    this.isOutside = true
    this.rotateLerp = 0

    T.bM(this, ['move'])

    this.mm = new Mousemove({
      cb: this.move
    })

    this.init()
  }

  init() {
    const { top, bottom, height, left, right } = this.parent.getBoundingClientRect()
    const { left: x, top: y } = this.el.getBoundingClientRect()

    this.mm.on()

    this.cursor = {
      x: 0,
      y: 0,
      old: {
        x: 0,
        y: 0
      },
      delta: {
        x: 0
      },
      bounds: {
        top,
        bottom,
        height,
        left,
        right
      },
      size: {
        x: this.el.offsetWidth / 2,
        y: this.el.offsetHeight / 2
      },
      transform: {
        x,
        y
      },
      initial: {
        x,
        y
      },
      lerp: {
        x: 0,
        y: 0
      }
    }

    this.listener('add')
  }

  destroy() {
    this.mm.off()
    this.listener('remove')
  }

  move(x, y) {
    this.cursor.x = x
    this.cursor.y = y
  }

  listener(a) {
    this.parent[a + 'EventListener']('mouseenter', this.mouseEnter.bind(this))
    this.parent[a + 'EventListener']('mouseleave', this.mouseLeave.bind(this))
  }

  reset() {
    this.resetting = true
    this.cursor.transform.x = 0
    this.cursor.transform.y = 0
    this.rotateTarg = 0
  }

  mouseEnter() {
    if (this.resetting) return
    
    this.enter = true
    this.el.classList.add('a')
  }

  mouseLeave() {
    if (this.resetting) return

    this.enter = false
    this.el.classList.remove('a')
  }

  update() {
    if (!this.enter && !this.needU) return

    const { lerp, transform, delta, old } = this.cursor
    const outside = this.cursor.y <= this.cursor.bounds.top || this.cursor.y >= this.cursor.bounds.bottom || this.cursor.x <= this.cursor.bounds.left || this.cursor.x >= this.cursor.bounds.right

    this.needU = Math.abs(Math.round(transform.x)) !== Math.abs(Math.round(lerp.x)) || Math.abs(Math.round(transform.y)) !== Math.abs(Math.round(lerp.y))

    // if (outside && this.resetToPosition) {
    //   transform.x = 0
    //   transform.y = 0

    //   if (!this.isOutside) {
    //     // this.cb && this.cb(true)
    //     this.isOutside = true
    //   }

    //   lerp.x = store.lerp(lerp.x, 0, 0.09)
    //   lerp.y = store.lerp(lerp.y, 0, 0.09)
    // } else if (outside && !this.resetToPosition) {
    //   if (!this.isOutside) {
    //     // this.cb && this.cb(true)
    //     this.isOutside = true
    //   }
    // } else if (!outside) {
    //   if (this.isOutside) {
    //     // this.cb && this.cb(false)
    //   }

    //   this.isOutside = false

    //   transform.x = this.cursor.x - this.cursor.size.x - this.cursor.initial.x
    //   transform.y = this.cursor.y - this.cursor.size.y - this.cursor.initial.y
    // }

    if (!this.resetting) {
      transform.x = this.cursor.x - this.cursor.size.x - this.cursor.initial.x
      transform.y = this.cursor.y - this.cursor.size.y - this.cursor.initial.y
      this.rotateTarg = this.rotateValue + delta.x * this.rotateForce
    }

    lerp.x = T.lerp(lerp.x, transform.x, 0.09)
    lerp.y = T.lerp(lerp.y, transform.y, 0.09)

    this.rotateLerp = T.lerp(this.rotateLerp, this.rotateTarg, 0.09)

    delta.x = old.x - lerp.x
    old.x = lerp.x

    const rotate = this.rotate ? `rotate(${this.rotateLerp}deg)` : ''

    this.el.style.transform = this.needU ? `translate3d(${lerp.x}px, ${lerp.y}px, 0) ` + rotate : `translate(${lerp.x}px, ${lerp.y}px) ` + rotate
  }
}
