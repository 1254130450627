import Block from './Block'
import FollowMouse from '../utils/FollowMouse'

export default class Home extends Block {
  init() {
    this.a = window.App
    this.elems()

    this.followMouse = new FollowMouse({
      parent: this.$titlesWrapper,
      el: this.$wrapper
    })

    this.animateH1 = T.M({
      el: this.$h1,
      p: {
        y: [101, 0]
      },
      e: 'o6',
      d: 800
    })
  }

  elems() {
    this.items = []
    this.lines = []

    this.$h1 = T.select('h1 span', this.el)
    this.$titlesWrapper = T.select('.b-home__titles', this.el)
    this.$titles = T.selectAll('.b-home__title', this.el)
    this.$wrapper = T.select('.b-home__images', this.el)
    this.$wrapperW = T.select('.b-home__images-w', this.el)
    this.$images = T.selectAll('.b-home__image', this.el)
    this.$imagesInner = T.selectAll('.b-home__image-w', this.el)

    let t = this.$titles[0].offsetTop
    let lines = []

    for (let i = 0; i < this.$images.length; i++) {
      const chars = T.selectAll('.c', this.$titles[i])
      const top = this.$titles[i].offsetTop

      if (t === top) {
        lines.push(this.$titles[i])

        i === this.$titles.length - 1 && this.lines.push(lines)
      } else {
        t = top
        this.lines.push(lines)

        lines = []
        lines.push(this.$titles[i])

        i === this.$titles.length - 1 && this.lines.push(lines)
      }

      this.items.push({
        chars,
        animateChars: T.M({
          el: chars,
          stagger: 17,
          p: {
            y: [101, 0]
          },
          d: 1000,
          e: 'o6',
          delay: 50 * i
        }),
        image: {
          targ: 0,
          lerp: 0,
          el: this.$images[i]
        },
        imageInner: {
          targ: 0,
          lerp: 0,
          el: this.$imagesInner[i]
        }
      })
    }

    for (let i = 0; i < this.lines.length; i++) {
      for (let j = 0; j < this.lines[i].length; j++) {
        const h = document.createElement('span')

        h.classList.add('hyphen')

        j !== this.lines[i].length - 1 && this.lines[i][j].insertAdjacentElement('afterend', h)
      }
    }

    this.$hyphens = T.selectAll('.hyphen', this.el)

    this.animateHyphens = T.M({
      el: this.$hyphens,
      p: {
        scaleX: [0, 1]
      },
      e: 'o6',
      stagger: 50,
      delay: 400
    })
  }

  listen(t) {
    for (let i = 0; i < this.$titles.length; i++) {
      T.listen(this.$titles[i], t, 'mouseenter', this.changeImage.bind(this, i))
      T.listen(this.$titles[i], t, 'click', this.onClick.bind(this, i))
    }
  }

  on() {
    this.listen('a')
    this.animateH1.play()
    this.animateHyphens.play()

    for (let i = 0; i < this.items.length; i++) {
      const { animateChars } = this.items[i]

      animateChars.play()
    }
  }

  off() {
    this.listen('r')
  }

  onClick(i) {
    if (this.followMouse.resetting) return

    this.followMouse.reset()
    this.animateH1.play({
      p: {
        y: {
          newEnd: -101
        }
      }
    })

    this.animateHyphens.play({
      reverse: true,
      delay: 0
    })

    for (let j = 0; j < this.items.length; j++) {
      const { animateChars } = this.items[j]

      animateChars.play({
        e: 'o5',
        d: 800,
        p: {
          y: {
            newEnd: -106
          }
        }
      })
    }

    T.M({
      e: 'io5',
      d: 1000,
      delay: 40,
      update: (e) => {
        const v = 15 * e.progressE

        this.$wrapper.style.clipPath = `polygon(${15 - v}% ${15 - v}%, ${85 + v}% ${15 - v}%, ${85 + v}% ${85 + v}%, ${15 - v}% ${85 + v}%)`
      },
      cb: () => {
        this.a.transitions.mutate(this.$titles[i].dataset.href, 'project', 0, { fromHome: true })
      }
    }).play()

    T.M({
      el: this.$wrapperW,
      e: 'io5',
      d: 1000,
      p: {
        scale: [0.85, 1]
      }
    }).play()
  }

  changeImage(index) {
    if (this.followMouse.resetting) return

    for (let i = 0; i < this.items.length; i++) {
      const { image, imageInner } = this.items[i]

      image.el.style.zIndex = Math.abs(index - i)

      if (i !== index) {
        image.targ = -100
        imageInner.targ = 90
      } else {
        image.targ = 100
        image.lerp = 100
        imageInner.targ = -90
        imageInner.lerp = -90

        image.el.style.transform = `translate3d(0, ${image.lerp}%, 0)`
        imageInner.el.style.transform = `translate3d(0, ${imageInner.lerp}%, 0)`

        image.targ = 0
        imageInner.targ = 0
      }
    }
  }

  unequal(p) {
    return T.round(Math.abs(p.lerp - p.targ)) !== 0
  }

  update() {
    this.followMouse.update()

    for (let i = 0; i < this.items.length; i++) {
      const { image, imageInner } = this.items[i]

      image.lerp = T.lerp(image.lerp, image.targ, 0.09)
      imageInner.lerp = T.lerp(imageInner.lerp, imageInner.targ, 0.09)
      this.unequal(image) && (image.el.style.transform = `translate3d(0, ${image.lerp}%, 0)`)
      this.unequal(imageInner) && (imageInner.el.style.transform = `translate3d(0, ${imageInner.lerp}%, 0)`)
    }
  }
}
