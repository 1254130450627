export default class Block {
  constructor(el) {
    this.el = el
  }

  init() {}

  on() {}

  off() {}

  resize() {}

  update() {}
}
