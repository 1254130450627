import CacheManager from './CacheManager'
import Router from './Router'
import Renderer from '../routes/Renderer'
import Loader from './Loader'

export default class TransitionManager {
  constructor({ transitions }) {
    this.bindMethods()

    this.a = window.App
    this.a.transition = {}
    this.a.mutating = false
    this.transitions = transitions
    this.app = T.select('#app')
    this.router = new Router()

    this.onPopstate()
    document.body.addEventListener('click', this.eD)

    this.intro()
  }

  bindMethods() {
    this.eD = this.eD.bind(this)
    this.in = this.in.bind(this)
    this.out = this.out.bind(this)
  }
  
  onPopstate() {
    let notReady = document.readyState !== 'complete'

    window.onload = () => {
      setTimeout(() => notReady = false, 0)
    }

    window.addEventListener('popstate', (e) => {
      if (notReady && document.readyState === 'complete') {
        e.cancelable && e.preventDefault()
        e.stopImmediatePropagation()
      }

      this.out(window.location.pathname, 'back')
    })
  }

  eD(e) {
    let isLink = false
    let isSubmit = false
    let el = e.target
    const tag = el.tagName

    if (tag === 'A') isLink = true
    else if ((tag === 'INPUT' || tag === 'BUTTON') && el.type === 'submit') isSubmit = true
    else el = el.parentNode

    if (isLink) {
      const url = el.href

      if (el.target === '_blank') {
        T.pD(e)
        window.open(url)
      } else if (url.substring(0, 6) !== 'mailto' && url.substring(0, 3) !== 'tel') {
        T.pD(e)

        const urlRgx = url.replace(/^.*\/\/[^/]+/, '')
        const custom = el.hasAttribute('data-transition') ? el.dataset.transition : false

        if (this.a.mutating || urlRgx !== this.a.route.new.url) {
          this.a.mutating = true
          this.out(urlRgx, el, custom)
        }
      }
    } else if (isSubmit) T.pD(e)
  }

  intro(cb)  {
    this.xhr((data) => {
      this.cache = new CacheManager(data.cache)
      this.add(this.app, data.app)
      this.main = document.querySelector('main')
      this.a.renderer = new Renderer(this.main)

      this.transitions.default = new this.transitions.default()

      if (this.transitions.custom) {
        Object.entries(this.transitions.custom).forEach(([name, Transition]) => this.transitions.custom[name] = new Transition())
      }

      this.loadSprite().then(() => {
        this.a.loader = new Loader()
      })
      
      cb && cb()
    })
  }

  loadSprite() {
    return new Promise((resolve) => {
      const sprite = T.select('.loader__sprite')
      const image = T.select('img', sprite)
      const img = new Image()

      img.src = sprite.dataset.img

      img.onload = () => {
        image.src = img.src
        resolve()
      }
    })
  }

  xhr(cb) {
    let url = this.a.route.new.url + '?cache=true'
    let rq = new XMLHttpRequest

    rq.open('GET', url, true)
    rq.onreadystatechange = (e) => {
      if (4 === rq.readyState && 200 === rq.status)
        try {
          const r = JSON.parse(rq.responseText)
          cb(r)
        } catch (e) {
          console.error('Error while parsing JSON', rq.responseText)
        }
    }

    rq.send(null)
  }

  in(custom = false) {
    let e = this.cache.get()

    document.title = e.title + ' – Valentine Lafont'

    if (this.a.target !== 'back') {
      const url = this.a.route.new.url

      history.pushState({ page: url }, '', url)
    }

    this.a.transition = {
      insertNew: () => {
          this.add(this.main, e.html)
          this.a.renderer = new Renderer(this.main.lastChild)
      },
      removeOld: () => {
          const old = this.main.children[0]
          old.parentNode.removeChild(old)
      }
    }

    if (custom) custom.in()
    else this.transitions.default.in()
  }

  out(url, el, custom, data = {}) {
    this.custom = false
    this.router.mutation(url)
    this.a.target = el
    this.a.fromBack = el === 'back'
    this.a.core.off()

    // this.a.transition.getData = (e) => {
    //   const to = this.cache.get()

    //   this.in({data: to})
    // }

    if (!custom) {
      this.transitions.default.out({ done: this.in, data })
    } else {
      this.custom = this.transitions.custom[custom]
      this.custom.out({ done: this.in.bind(this, this.custom), data })
    }
  }

  mutate(url, transition, delay = 0, data = {}) {
    const urlRgx = url.replace(/^.*\/\/[^/]+/, '')
    const custom = transition || false

    if (this.a.mutating || urlRgx !== this.a.route.new.url) {
      this.a.mutating = true
      delay === 0 ? this.out(urlRgx, 'js', custom, data) : setTimeout(() => this.out(urlRgx, 'js', custom, data), delay)
    }
  }

  add(p, e) {
    p.insertAdjacentHTML('beforeend', e)
  }
}