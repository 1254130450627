import RafR from './RafR'

export default class Sprite {
  constructor({ el }) {
    T.bM(this, ['update'])

    this.raf = new RafR(this.update)

    this.el = el
    this.sprite = T.select('img', this.el)

    this.cols = this.el.dataset.cols
    this.rows = this.el.dataset.rows
    this.total = parseInt(this.el.dataset.frames) || this.cols * this.rows
    this.last = 0
    this.frameRate = this.el.dataset.framerate || 24
    this.tick = 1

    this.frame = {
      width: 100 / this.cols,
      height: 100 / this.rows
    }

    this.init()
  }

  init() {
    this.setup()
    this.raf.run()
  }

  setup() {
    this.frames = []

    for (let i = 0; i < this.total; i++) {
      const c = i % this.cols
      const r = Math.floor(i / this.cols)

      this.frames.push({
        column: c,
        row: r,
        x: c * this.frame.width + '%',
        y: r * this.frame.height + '%'
      })
    }
  }

  goToFrame(i) {
    this.sprite.style.transform = `translate3d(-${this.frames[i].x}, -${this.frames[i].y}, 0)`
  }

  update(e) {
    if (this.tick === this.total) this.raf.stop()

    if (e - this.last > 500 / this.frameRate) {
      this.last = e
      this.current = this.tick % this.total
      this.goToFrame(Math.round(this.tick % this.total))
      this.tick++
    }
  }
}
