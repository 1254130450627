import Block from './Block'
import Mousemove from '../utils/Mousemove'

export default class ImageSlider extends Block {
  constructor(el) {
    super(el)

    this.elems()

    this.a = window.App

    T.bM(this, ['move', 'down', 'up'])
  
    this.drag = {
      target: 0,
      current: 0,
      currentR: 0,
      start: 0,
      end: 0,
      max: 0
    }
  
    this.mm = new Mousemove({
      cb: this.move,
      el: this.el
    })
  }

  on() {
    this.mm.on()
    this.listener('a')
  }

  off() {
    this.mm.off()
    this.listener('r')
  }

  listener(t) {
    if (T.detect.isMobile) {
      T.listen(this.el, t, 'touchstart', this.down)
      T.listen(this.el, t, 'touchend', this.up)
    } else {
      T.listen(this.el, t, 'mousedown', this.down)
      T.listen(this.el, t, 'mouseup', this.up)
    }
  }

  elems() {
    this.slider = T.select('.b-image-slider__w', this.el)
  }

  move(x) {
    if (this.isDown) {
      this.drag.target = T.round(this.drag.end + x - this.drag.start)
      this.drag.target = T.clamp(this.drag.target, -this.drag.max, 0)
    }
  }

  down(e) {
    this.isDown = true
    this.drag.start = e.changedTouches ? e.changedTouches[0].pageX : e.pageX
  }

  up(e) {
    const x = e.changedTouches ? e.changedTouches[0].pageX : e.pageX
    
    this.isDown = false
    this.drag.end = this.drag.target
  }

  resize() {
    this.drag.max = this.a.w.ww < 768 ? this.slider.getBoundingClientRect().right - this.a.w.ww + this.a.w.ww * 0.03 : this.slider.getBoundingClientRect().right - this.a.w.ww + this.a.w.ww / 12 + 5
  }

  update() {
    this.needU = this.drag.target !== this.drag.currentR

    if (this.needU) {
      this.drag.current += (this.drag.target - this.drag.current) * 0.09
      this.drag.currentR = T.round(this.drag.current)
      this.slider.style.transform = `translate3d(${this.drag.currentR}px, 0, 0)`
    }
  }
}
