export default class Split {
  constructor({ el, cb, wrap }) {
    this.el = el
    this.cb = cb
    this.wrap = wrap
    this.class = 'line'
    this.html = this.el.innerHTML
    this.style = null
    this.lines = null
  }

  resize() {
    this.split()
    this.cb && this.cb()
  }

  createLine(c) {
    const style = this.style && `style="${this.style}"`
    const html = c.slice(0, -1)

    return this.wrap ? `<span class="_l"><span class="${this.class}" ${style || ''}>${html}</span></span>` : `<span class="_l ${this.class}" ${style}>${html}</span>`
  }

  split() {
    this.lines && (this.style = this.lines[0].style.cssText)
    this.el.innerHTML = ''

    const words = this.html.split(' ')
    const wordsL = words.length
    let html = ''

    for (let i = 0; i < wordsL; i++) {
      const word = `<span>${words[i]}</span> `

      html += word
    }

    this.el.innerHTML = html
    
    const els = T.selectAll('span', this.el)
    let top = els[0].offsetTop
    let lines = ''
    let line = ''

    for (let i = 0; i < els.length; i++) {
      const t = els[i].offsetTop

      if (t === top) {
        line += words[i] + ' '

        i === els.length - 1 && (lines += this.createLine(line))
      } else {
        top = t
        lines += this.createLine(line)

        line = ''
        line += words[i] + ' '

        i === els.length - 1 && (lines += this.createLine(line))
      }
    }

    this.el.innerHTML = lines

    this.lines = T.selectAll(`.${this.class}`, this.el)
  }
}
