export default class Raf {
  constructor(t) {
    this.arr = []
    this.pause = 0
    this.on = true

    this.bindMethods()

    this.raf()
  }

  bindMethods() {
    this.loop = this.loop.bind(this)
    this.tOff = this.tOff.bind(this)
    this.tOn = this.tOn.bind(this)
  }

  tOff() {
    this.on = false
  }

  tOn(e) {
    for (let i = this.arr.length - 1; 0 <= i; i--) {
      this.arr[i].sT += e
      this.on = true   
    }
  }

  add(e) {
    this.arr.push(e)
  }

  remove(e) {
    for (let i = this.arr.length - 1; 0 <= i; i--) {
      if (this.arr[i].id === e)
        return void this.arr.splice(i, 1)
    }
  }

  loop(e) {
    if (this.on) {
      for (let i = this.arr.length - 1; 0 <= i; i--) {
        const arr = this.arr[i]

        arr.sT || (arr.sT = e)

        const s = e - arr.sT

        arr.cb(s)
      }
    }

    this.raf()
  }

  raf() {
    if (typeof window !== 'undefined') window.requestAnimationFrame && requestAnimationFrame(this.loop)
  }
}