export default class VirtualScrollM {
  constructor(p) {
    const cb = p.cb
    
    this.cbY = cb.y

    T.bM(this, ['run'])
  }

  on() {
    this.listener('a')
  }

  off() {
    this.listener('r')
  }

  listener(a) {
    T.listen(window, a, 'scroll', this.run)
  }

  run() {
    this.cbY(pageYOffset)
  }
}