export default class VirtualScroll {
  constructor(p) {
    this.cb = p.cb

    this.store = {
      scroll: {
        on: false
      },
      gap: 0,
      isFF: T.detect.isFirefox
    }

    T.bM(this, ['fn'])

    const events = ['mouseWheel']

    p.key && (events[1] = 'keydown')

    for (let i = 0; i < events.length; i++) T.listen(document, 'a', events[i], this.fn)
  }

  on() {
    this.tick = false
    this.store.scroll.on = true
  }

  off() {
    this.store.scroll.on = false
  }

  resize() {
    this.store.gap = window.App.w.wh - 40
  }

  fn(e) {
    this.e = e
    this.eventType = e.type
    this.eventKey = e.key

    if (this.eventType === 'keydown' && this.eventKey === 'Tab') T.pD(e)

    if (this.store.scroll.on) {
      if (!this.tick) {
        this.tick = true
        this.run()
      }
    }
  }

  run() {
    this.eventType === 'wheel' ? this.wheel() : this.eventType === 'mousewheel' ? this.mousewheel() : this.eventType === 'keydown' && this.key()
  }

  mousewheel() {
    let delta = this.e.wheelDeltaY ? this.e.wheelDeltaY : this.e.wheelDelta
    this.store.scroll.y = -delta
    this.callback()
  }

  wheel() {
    let delta = this.e.wheelDeltaY || this.e.deltaY * -1

    this.store.isFF && this.e.deltaMode === 1 && (delta *= 60)
    
    delta *= 0.55
    this.store.scroll.y = -delta
    this.callback()
  }

  key() {
    const up = this.eventKey === 'ArrowUp'
    const down = this.eventKey === 'ArrowDown'
    const sp = this.eventKey === ' '

    if (up || down || sp) {
      let y = 0

      if (up) y = -100
      else if (down) y = 100
      else if (sp) {
        const dir = this.e.shiftKey ? -1 : 1

        y = this.store.gap * dir
      }

      this.store.scroll.y = y
      this.callback()
    } else this.tick = false
  }

  callback() {
    this.cb(this.store.scroll.y)
    this.tick = false
  }
}