import blocks from '../blocks/blocks'

export default class Renderer {
  constructor(view) {
    this.view = view

    this.setup()
  }

  setup() {
    this.blocks = []

    for (let i = 0; i < blocks.length; i++) {
      const foundBlocks = this.view.querySelectorAll('.' + blocks[i].name)

      if (blocks[i].mobile === false && T.detect.isMobile) continue

      const block = {
        name: blocks[i].name,
        instances: []
      }
      
      for (let j = 0; j < foundBlocks.length; j++) {
        block.instances.push({
          el: foundBlocks[j],
          class: new blocks[i].Class(foundBlocks[j])
        })
      }

      this.blocks.push(block)
    }
  }

  init() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) this.blocks[i].instances[j].class.init()
    }
  }

  on() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) this.blocks[i].instances[j].class.on()
    }
  }

  off() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) this.blocks[i].instances[j].class.off()
    }
  }

  resize() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) this.blocks[i].instances[j].class.resize()
    }
  }

  update() {
    for (let i = 0; i < this.blocks.length; i++) {
      for (let j = 0; j < this.blocks[i].instances.length; j++) this.blocks[i].instances[j].class.update()
    }
  }
}
