import RafR from "./RafR"

export default class DelayRaf {
  constructor(cb, d) {
    this.cb = cb
    this.d = d

    this.bindMethods()
    this.raf = new RafR(this.loop)
  }

  bindMethods() {
    this.loop = this.loop.bind(this)
  }

  run(){
    this.d === 0 ? this.cb() : this.raf.run()
  }

  stop() {
    this.raf.stop()
  }

  loop(e) {
    const i = Math.min(Math.max(e, 0), this.d)

    if (Math.min(Math.max(i / this.d, 0), 1) === 1) {
      this.stop()
      this.cb()
    }
  }
}