export default class ScrollTo {
  constructor(p) {
    this.a = window.App
    this.isScrolling = false
    this.yUp = p.yUp
    T.bM(this, ['go', 'click'])
  }

  on() {
    this.listen('a')
  }

  off() {
    this.listen('r')
  }

  stop() {
    if (this.isScrolling) {
      this.anim.pause()
      this.isScrolling = false
    }
  }

  listen(t) {
    T.listen('[data-scroll-to]', t, 'click', this.click)
  }

  click() {
    this.go()
  }

  go(v, opts = {}) {
    const s = this.a.scroll.y
    const to = v || this.a.w.wh

    const sto = s + (to - s)

    this.anim = T.M({
      e: 'o5',
      d: 1000,
      update: (e) => {
        const t = T.lerp(s, sto, e.progressE)

        this.yUp(t)
      },
      cb: () => {
        opts.cb && opts.cb()
      }
    })

    this.isScrolling = true
    this.anim.play()
  }
}
