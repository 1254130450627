export default class Intersect {
  constructor() {
    this.a = window.App
  }

  init() {
    this.els = []
    this.stickys = []
    
    const s = T.selectAll('.page')[0].children

    for (let i = 0; i < s.length; i++) {
      if (s[i].dataset.scrollDisable === undefined) {
        this.els.push({ dom: s[i] })
      }
    }

    const sticky = T.selectAll('[data-sticky]')

    for (let i = 0; i < sticky.length; i++) {
      this.stickys.push({
        dom: sticky[i],
        targ: T.select(sticky[i].dataset.sticky)
      })
    }
  }

  resize() {
    const scroll = this.a.scroll.y
    const wh = this.a.w.wh

    for (let i = 0; i < this.els.length; i++) {
      const el = this.els[i]
      const r = T.round(-this.a.scroll.y)

      this.transform(el, r)

      const h = el.dom.offsetHeight
      const top = el.dom.getBoundingClientRect().top + scroll - wh
      const o = Math.min(top, 0) + h + wh
      const topMax = Math.max(top, 0)

      el.rect = {
        top,
        topMax,
        bottom: o + topMax
      }

      el.out = false
    }

    for (let i = 0; i < this.stickys.length; i++) {
      const s = this.stickys[i]
      const { top, height } = s.dom.getBoundingClientRect()
      const targTop = s.targ.getBoundingClientRect().top

      const offsetTop = (50 * this.a.w.wh / 100) - height / 2
      const offsetBottom = (50 * this.a.w.wh / 100) - height / 2

      s.rect = {
        top: top + this.a.w.wh - offsetTop,
        bottom: targTop + s.targ.offsetHeight + (this.a.w.wh - s.dom.offsetHeight - offsetTop) - offsetBottom,
        height: s.dom.offsetHeight
      }
    }
  }

  run() {
    const scroll = this.a.scroll.y

    for (let i = 0; i < this.els.length; i++) {
      const el = this.els[i]
      const t = T.round(-scroll)

      if (scroll > el.rect.top && scroll <= el.rect.bottom) {
        el.out && (el.out = false)
        this.transform(el, t)
      } else if (!el.out) {
        el.out = true
        this.transform(el, t)
      }
    }

    for (let i = 0; i < this.stickys.length; i++) {
      const el = this.stickys[i]
      
      if (scroll > el.rect.top - this.a.w.wh && scroll <= el.rect.bottom - this.a.w.wh) {
        const t = scroll - el.rect.top + this.a.w.wh
        el.out && (el.out = false)
        this.transform(el, t)
      } else {
        if (scroll > el.rect.bottom - this.a.w.wh) {
          const t = el.rect.bottom - el.rect.top
          this.transform(el, t)
        }
      }

      // else if (!el.out) {
      //   el.out = true
      //   // this.transform(el, t)
      // }
    }
  }

  transform(el, t) {
    el.dom.style.transform = `translate3d(0, ${t}px, 0)`
  }
}