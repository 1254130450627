class Grid {
  constructor({ width, col, gap, mobile = 6, color = '#000', full = false, opacity = 0.3 }) {
    this.width = width
    this.col = col
    this.gap = gap
    this.mobile = mobile
    this.color = color
    this.opacity = opacity

    this.keypress = 0
    this.full = full
    this.isMobile = /mobi|android|tablet|ipad|iphone/.test(navigator.userAgent.toLowerCase()) || (navigator.platform === 'MacIntel' && 1 < navigator.maxTouchPoints)

    this.bindMethods()
    this.create()
    this.isMobile && this.createToggler()
    this.events()

    this.gridWrapper.classList.toggle('f', this.full)
  }

  bindMethods() {
    this.create = this.create.bind(this)
    this.events = this.events.bind(this)
    this.resize = this.resize.bind(this)
    this.show = this.show.bind(this)
  }

  events() {
    document.body.addEventListener('keyup', this.show)
    window.addEventListener('resize', this.resize)
    this.toggler && this.toggler.addEventListener('click', this.show)
  }

  create() {
    this.gridWrapper = document.createElement('div')
    this.gridWrapper.id = 'g-debug'

    this.grid = document.createElement('div')
    this.grid.id = 'g'
    this.grid.style.width = this.width + 'vw'

    this.gridWrapper.appendChild(this.grid)

    const n = window.innerWidth < 768 ? this.mobile : this.col

    for (let i = 0; i < this.col; i++) {
      const column = document.createElement('div')

      column.style.width = 100 / n + '%'
      column.style.marginLeft = this.gap / 2 + 'px'
      column.style.marginRight = this.gap / 2 + 'px'
      column.style.display = i < n ? 'block' : 'none'

      document.documentElement.style.setProperty('--grid-color', this.color)

      this.grid.appendChild(column)
    }

    this.columns = this.grid.querySelectorAll('div')
    document.body.appendChild(this.gridWrapper)
  }

  createToggler() {
    this.toggler = document.createElement('div')
    this.toggler.id = 'g-btn'
    this.toggler.style.opacity = this.opacity
    document.body.appendChild(this.toggler)
  }

  update(n) {
    for (let i = 0; i < this.col; i++) {
      this.columns[i].style.width = 100 / n + '%'
      this.columns[i].style.display = i < n ? 'block' : 'none'
    }
  }

  show(e) {
    if  (e.key === 'g' || e.key === 'G' || e.type === 'click') {
      e.cancelable && e.preventDefault()
      this.grid.style.opacity = this.keypress++ % 2 === 0 ? this.opacity : 0
    } else if (e.key === 'f' || e.key === 'F' || e.type === 'click') {
      e.cancelable && e.preventDefault()
      this.gridWrapper.classList.toggle('f', (this.full = !this.full))
    }
  }

  resize() {
    this.update(window.innerWidth < 768 ? this.mobile : this.col)
  }
}

export default Grid