import Home from './Home'
import Hero from './Hero'
import Next from './Next'
import AboutHero from './AboutHero'
import ImageSlider from './ImageSlider'
import Contact from './Contact'

const blocks = [
  {
    name: 'b-home',
    Class: Home,
    mobile: false
  },
  {
    name: 'b-about-hero',
    Class: AboutHero
  },
  {
    name: 'b-image-slider',
    Class: ImageSlider
  },
  {
    name: 'c-hero',
    Class: Hero
  },
  {
    name: 'c-next',
    Class: Next
  },
  {
    name: 'b-contact',
    Class: Contact
  }
]

export default blocks
