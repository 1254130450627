export default class Menu {
  constructor() {
    this.a = window.App

    this.opened = false
    this.animate = {}

    T.bM(this, ['toggle'])

    this.elems()
    this.events()
    this.prepare()
  }

  elems() {
    this.toggler = T.select('.header__toggler')
    this.menu = T.select('.header__menu')
    this.inner = T.select('.header__menu-inner')
    this.items = T.selectAll('.header__item')
    this.overlay = T.select('.header__overlay')
  }
  
  events() {
    this.toggler.addEventListener('click', this.toggle)
  }

  prepare() {
    const o = T.M({
      el: this.menu,
      e: 'o6',
      d: 1200,
      p: {
        y: [-100, 0]
      }
    })

    const i = T.M({
      el: this.inner,
      e: 'o6',
      d: 1200,
      p: {
        y: [100, 0]
      }
    })

    const items = []

    for (let i = 0; i < this.items.length; i++) {
      const a = T.M({
        el: this.items[i],
        e: 'o6',
        d: 1200,
        delay: 150 + 100 * i,
        p: {
          y: [100, 0]
        }
      })

      items.push(a)      
    }

    this.animate = [o, i, ...items]
  }
  
  toggle() {
    this.overlay.classList.toggle('a', !this.opened)
    this.toggler.classList.toggle('a', !this.opened)
    this.opened ? this.close() : this.open()
  }

  play(reverse = false) {
    for (let i = 0; i < this.animate.length; i++) reverse ? this.animate[i].play({ reverse: true }) : this.animate[i].play()
  }

  removeOverlay() {
    this.overlay.classList.remove('a')
  }

  open() {
    this.opened = true

    for (let i = 0; i < this.items.length; i++) {
      const a = this.items[i].href.replace(/^.*\/\/[^\/]+/, '') === this.a.route.new.url ? 'add' : 'remove'

      this.items[i].classList[a]('a')
    }

    this.play()
  }

  close() {
    this.opened = false
    this.play(true)
  }
}